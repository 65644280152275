@import "./jpmc-qr-ui-common/styles/less/variables/_colors";
@import "./jpmc-qr-ui-common/styles/less/variables/_fontFamilies";
@import "./jpmc-qr-ui-common/styles/less/variables/_fontWeights";

$baseUrl: 'https://static.chasecdn.com/content/dam/cpo-static/fonts';

html {
  overflow: hidden;
}

body {
  background-color: $color-gray5;
  font-family: $font-family-regular;
}

svg {
  pointer-events: none;
}

path {
  pointer-events: none;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-regular;
  font-display: swap;
  src: url('#{$baseUrl}/opensans-regular.eot?#iefix') format('embedded-opentype'),
       url('#{$baseUrl}/opensans-regular.woff') format('woff'),
       url('#{$baseUrl}/opensans-regular.ttf') format('truetype'),
       url('#{$baseUrl}/opensans-regular.svg#opensans-regular') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-display: swap;
  src: url('#{$baseUrl}/opensans-semibold.eot?#iefix') format('embedded-opentype'),
       url('#{$baseUrl}/opensans-semibold.woff') format('woff'),
       url('#{$baseUrl}/opensans-semibold.ttf') format('truetype'),
       url('#{$baseUrl}/opensans-semibold.svg#opensans-semibold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-bold;
  font-display: swap;
  src: url('#{$baseUrl}/opensans-bold.eot?#iefix') format('embedded-opentype'),
       url('#{$baseUrl}/opensans-bold.woff') format('woff'),
       url('#{$baseUrl}/opensans-bold.ttf') format('truetype'),
       url('#{$baseUrl}/opensans-bold.svg#opensans-bold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-extra-bold;
  font-display: swap;
  src: url('#{$baseUrl}/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
       url('#{$baseUrl}/opensans-extrabold.woff') format('woff'),
       url('#{$baseUrl}/opensans-extrabold.ttf') format('truetype'),
       url('#{$baseUrl}/opensans-extrabold.svg#opensans-extrabold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-light;
  font-display: swap;
  src: url('#{$baseUrl}/opensans-light.eot?#iefix') format('embedded-opentype'),
       url('#{$baseUrl}/opensans-light.woff') format('woff'),
       url('#{$baseUrl}/opensans-light.ttf') format('truetype'),
       url('#{$baseUrl}/opensans-light.svg#opensans-light') format('svg');
}

@font-face {
  font-family: 'Open Sans-Semi Bold';
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-display: swap;
  src: url('#{$baseUrl}/opensans-semibold.eot?#iefix') format('embedded-opentype'),
       url('#{$baseUrl}/opensans-semibold.woff') format('woff'),
       url('#{$baseUrl}/opensans-semibold.ttf') format('truetype'),
       url('#{$baseUrl}/opensans-semibold.svg#opensans-semibold') format('svg');
}