$color-black-light-opacity: rgba(0, 0, 0, .28);
$color-blue-dark: #0A4386;
$color-blue-dark-opacity: rgba($color-blue-dark, 0.08);
$color-blue-dark2: #0060F0;
$color-blue-dark2-opacity: rgba($color-blue-dark2, 0.08);
$color-blue-med-dark: #004692;
$color-blue-med-dark1: #005EB8;
$color-blue-med-dark2: #005DB9;
$color-blue-light: #0B6EFD;
$color-blue-light2: #EBF3FE;
$color-blue: #126BC5;
$color-blue-lighter: #0092FF;
$color-gray-dark: #717171;
$color-gray-dark2: #666666;
$color-gray-dark3: #565A5D;
$color-gray-dark4: #949494;
$color-gray-darker: #414042;
$color-gray-light: #D6D6D6;
$color-gray-light2: #CCCCCC;
$color-gray-lighter: #F7F7F7;
$color-gray-lighter2: #F2F2F2;
$color-gray-lighter3: #E5E5E5;
$color-gray-lighter4: #EDE9E3;
$color-gray-lighter5: #E2E4E5;
$color-gray-lightest: #F9F6F3;
$color-gray: #959595;
$color-gray2: #979797;
$color-gray3: #EDEDED;
$color-gray4: #676767;
$color-gray5: #F6F4F1;
$color-gray6: #EBF3FA;
$color-gray7: #F5F7F8;
$color-green: #128842;
$color-green2: #398100;
$color-green-dark: #0F783B;
$color-white-opacity-half: rgba(256, 256, 256, 0.5);
$color-white-opacity-light: rgba(256, 256, 256, 0.15);
$color-red: #BF2155;
$color-white: #FFF;

$color-black-text: #101820;
$color-negative: #B80009;
$color-positive: $color-green;